<template>
  <div class="terminal-min-height">
    <portal to="header">
      <terminal-header
          :title="$t('Assign')"
          @barcode="handleBarcode"
      />
    </portal>

    <div v-if="0">
      <div
          class="text-center q-pa-sm"
          style="height: 190px;"
      >
        <img
            src="assets/terminal-icons-md/IMG-0951.png"
            style="width: auto; height: 100%; object-fit: contain;"
        >
      </div>

      <h6 class="q-my-none text-center">
        {{ $t('Well done!') }}
      </h6>
    </div>

    <div class="text-center">
      <h6 class="q-my-none q-mr-sm">
        {{ $t('Please, scan SKU/barcode or type the name of the product') }}
      </h6>
    </div>

    <offers-search ref="offerSearch" @selected="searchProductsSelected" @search="handleSearch"
                   :options="searchOptions"/>

    <div v-if="offer" :style="`height: ${terminalContainerHeight}px;`">
      <div class="q-pa-sm border-bottom row items-center text-grey-7 text-weight-bold q-mb-xs">
        <h6 class="q-ma-none text-subtitle2 text-weight-bold">
          {{ $t('Products') }}
        </h6>
      </div>

      <div v-if="action === 'offer'">
        <div v-if="!offer && !$service.offer.isLoading" class="q-pa-md q-gutter-sm">
          <q-banner inline-actions rounded class="bg-orange text-white">
            {{ $t('There was no products found') }}
          </q-banner>
        </div>

        <div class="q-pa-xs q-mt-sm border-top--bold" style="max-width: calc(100vw - 16px);">
          <q-card-section class="q-pa-none border">
            <q-table
                row-key="id"
                :rows="[offer]"
                :columns="columns"
                v-model:pagination="pagination"
                :loading="isLoading"
                :filter="filter"
                :rows-per-page-options="[]"
                table-header-class="d-none"
                virtual-scroll
                binary-state-sort
                flat
            >
              <template v-slot:loading>
                <q-inner-loading
                    showing
                    color="primary"
                />
              </template>

              <template v-slot:body="props">
                <q-tr
                    :props="props"
                >
                  <q-td
                      key="image"
                      :props="props"
                      auto-width
                  >
                    <img
                        :src="props.row.image ? props.row.image : fallbackImage"
                        style="width: 80px; height: 70px; object-fit: contain;"
                        @error="onImageLoadFailure"
                    >
                  </q-td>

                  <q-td
                      key="details"
                      :props="props"
                      style="word-wrap: break-word; white-space: normal !important;"
                  >
                    <div class="text-subtitle1">
                      {{ `${props.row.name} (${props.row.id})` }}
                    </div>

                    <div class="text-caption">{{ props.row.article }}</div>

                    <div>{{ props.row.type }}</div>
                  </q-td>
                  <q-td style="max-width:100px;">
                  <span v-if="!props.row.items || props.row.items.length === 0 || !props.row.items[0]">
                    <strong>-</strong><br>
                  </span>
                    <span v-else>
                    <table class="table table-valign-middle">
                      <tbody>
                        <tr
                            v-for="item in isItems(props.row.items)"
                            :key="item.id"
                        >
                          <td>
                            <label :class="`q-py-xs q-px-sm rounded bg-${itemStates[item.state]}`">
                              {{ $t(stateName[item.state] || item.state) }}
                            </label>
                          </td>

                          <td>
                            <strong>{{ item.count }}</strong>
                          </td>

                          <td>{{ item.warehouse }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </span>
                  </q-td>
                </q-tr>
              </template>
            </q-table>
          </q-card-section>
        </div>

        <div class="q-pa-sm border-bottom row items-center text-grey-7 text-weight-bold q-mb-xs">
          <h6 class="q-ma-none text-subtitle2 text-weight-bold">
            {{ $t('Locations') }}
          </h6>
        </div>

        <div class="q-pa-xs q-mt-sm border-top--bold" style="max-width: calc(100vw - 16px);">
          <q-card-section class="q-pa-none border">
            <q-table
                row-key="id"
                :rows="places"
                :columns="columns"
                v-model:pagination="pagination"
                :loading="isLoading"
                :filter="filter"
                :rows-per-page-options="[]"
                table-header-class="d-none"
                virtual-scroll
                binary-state-sort
                flat
            >
              <template v-slot:loading>
                <q-inner-loading
                    showing
                    color="primary"
                />
              </template>

              <template v-slot:body="props">
                <q-tr
                    :props="props"
                >
                  <q-td
                      key="image"
                      :props="props"
                      auto-width
                  >
                    <label :class="`q-py-xs q-px-sm rounded bg-${itemStates[props.row.state]}`">
                      {{ $t(stateName[props.row.state] || props.row.state) }}
                    </label>
                  </q-td>

                  <q-td>
                    <h5 class="text-center">{{ props.row.count }}</h5>
                  </q-td>

                  <q-td
                      key="details"
                      :props="props"
                      style="max-width: 160px; word-wrap: break-word; white-space: normal !important;"
                  >
                    <div class="text-subtitle1 items-center">
                    <span v-if="props.row._embedded?.place">
                      <place-object
                          :data="props.row._embedded.place"
                          :type="props.row._embedded.place.type"
                          class="q-mb-sm"
                          @click="openPlace(props.row._embedded.place)"
                      />
                    </span>

                      <span v-else>
                        <span v-if="props.row.state === 'new'" class="row justify-center">
                          <q-btn color="white" text-color="black" :label="$t(`Tap to allocate`)"
                                 @click="openAllocate(props.row.count)"/>
                        </span>
                        <span v-else>
                          -
                        </span>
                      </span>
                    </div>

                  </q-td>
                </q-tr>
              </template>
            </q-table>
          </q-card-section>
        </div>
      </div>

      <div v-if="action === 'place'">
        <product-section :data="offer" :options="{image: {width:245, height: 245}}"/>

        <quantity-section
            :data="quantity"
            :options="quantityOptions"
            @click="handleCounterSubmit"
        />

        <counter-section :disable-additional-action="true" :data="counterValue" :options="counterOptions"
                         @click="handleRequestData"/>
      </div>

      <div v-if="action === 'allocate'">
        <product-section :data="offer" :options="{image: {width:245, height: 245}}"/>

        <quantity-section
            :data="allocationQuantity"
            :options="allocationQuantityOptions"
            @click="handleAllocationRequest"
        />

        <counter-section :disable-additional-action="true" :data="{max: totalCount}"
                         :options="allocationCounterOptions"
                         @click="handleAllocationRequest"/>
      </div>
    </div>

    <request-data-modal
        ref="requestDataModal"
        @submit="handleRequestData"
        @close="$emit('unblock')"
    />

  </div>
  <portal to="settings">
    <tiles :schema="tilesSchema"/>
  </portal>
  <confirm-modal ref="confirmModal"/>

  <location-logs ref="logs"/>
</template>

<script>
// Vuex
import { mapGetters, mapMutations } from 'vuex'

export default {
  name: 'ItemsChange',
  data () {
    return {
      action: 'offer',
      sku: null,
      offer: null,
      place: null,
      searchString: null,
      createItem: false,
      places: [],
      counterValue: 0,
      totalCount: 0,
      isDoneActive: false,
      isLoading: false,
      fallbackImage: 'assets/img/fallback-image/package.png',
      pagination: {
        descending: true,
        page: 1,
        rowsPerPage: 25,
        rowsNumber: 25
      },
      filter: '',
      columns: [
        {
          label: this.$t('Image'),
          name: 'image',
          align: 'center'
        },
        {
          label: this.$t('Details'),
          name: 'details',
          align: 'left'
        }
      ],
      options: {},
      itemStates: {
        new: 'grey-3',
        blocked: 'danger',
        booked: 'danger',
        deleted: 'danger',
        shipped: 'green text-white',
        normal: 'success',
        expected: 'green text-white',
        missing: 'grey'
      },
      stateName: {
        normal: 'On hand',
        blocked: 'Blocked',
        booked: 'Booked',
        new: 'Received',
        defected: 'Defected',
      },
      counterOptions: {
        allowNegative: true,
        allowNoLimit: true,
        allowAdditionalAction: {
          label: this.$t(`Move`)
        }
      },
      allocationCounterOptions: {
        label: this.$t(`Allocate`)
      },
      searchOptions: {
        // filter: [
        //   {
        //     type: 'in',
        //     alias: 'i',
        //     field: 'state',
        //     values: ['normal', 'blocked', 'new', 'expected', 'shipped', 'deleted']
        //   },
        //   {
        //     type: 'gte',
        //     field: 'items',
        //     value: 1
        //   }
        // ],
        'order-by': [
          { type: 'field', field: 'items', direction: 'desc' }
        ]
      }
    }
  },
  watch: {
    offer: {
      handler (value, oldValue) {
        this.counterValue = 0
        this.totalCount = 0
      },
      deep: true
    },
  },
  computed: {
    ...mapGetters([
      'terminalSettings',
      'terminalContainerHeight'
    ]),
    placesToMove () {
      if(this.places && this.place) {
        let places = this.places.filter(e=> e._embedded?.place?.id && this.place.id !== e._embedded.place.id )
        return places
      }
      return []
    },
    tilesSchema () {
      const options = []

      if (this.isDoneActive) {
        options.push({
          id: 1,
          label: this.$t('Back'),
          icon: 'refresh',
          value: true,
          onChanged: () => {
            if (this.action === 'place') {
              this.action = 'offer'
              this.place = null
            } else if (this.action === 'offer' && this.offer) {
              this.place = null
              this.offer = null
              this.updateTerminalSettings({ catchAll: true })
              this.$refs.offerSearch.handleSearch('')
              this.action = 'offer'
            }
          }
        })

        options.push({
          id: 2,
          label: this.$t('Start new cycle'),
          icon: 'refresh',
          value: true,
          onChanged: () => {
            this.handleReset()
          }
        })

        options.push({
          id: 3,
          label: this.$t('Complete'),
          icon: 'done',
          value: true,
          onChanged: () => {
            const description = this.$t('Do you want to start new cycle?')
            this.$refs.confirmModal.show({ description })
                .then(isOk => {
                  if (!isOk) {
                    this.$router.go()
                    return
                  }
                  this.handleReset()
                })
          }
        })
      } else {
        options.push({
          id: 1,
          label: this.$t('Back'),
          icon: 'refresh',
          value: true,
          onChanged: () => {
            if (this.action === 'place') {
              this.action = 'offer'
              this.place = null
            } else if (this.action === 'offer' && this.offer) {
              this.place = null
              this.offer = null
              this.updateTerminalSettings({ catchAll: true })
              this.$refs.offerSearch.handleSearch('')
              this.action = 'offer'
            }
          }
        })

        options.push({
          id: 2,
          label: this.$t('Start new cycle'),
          icon: 'refresh',
          value: true,
          onChanged: () => {
            this.handleReset()
          }
        })
      }

      if (this.offer) {
        options.push({
          id: 4,
          label: this.$t('Allocate'),
          icon: 'add_location',
          value: true,
          onChanged: () => {
            this.createItem = true

            this.handleAllocationRequest({
              event: 'counter.submit',
              data: {
                value: 1,
              }
            })
          }
        })

        options.push({
          id: 5,
          label: this.$t('Locations'),
          icon: 'history',
          value: true,
          onChanged: () => {
            this.$refs.logs.open(this.offer)
          }
        })
      }

      return options
    },
    totalNormalQuantity () {
      if (!this.places) {
        return 0
      }

      const foundPlace = this.places.find(place => place._embedded.place.id === this.place.id)

      return foundPlace.count
    },
    quantity () {
      const counterObj = {
        barcode: '',
        max: 0,
        current: 0
      }

      if (this.offer) {
        counterObj.max = this.totalNormalQuantity
        counterObj.current = this.totalCount

        if (counterObj.current === 0) {
          counterObj.current = this.totalNormalQuantity
        }
      }

      return counterObj
    },
    allocationQuantity () {
      const counterObj = {
        barcode: '',
        max: 0,
        current: 0
      }

      if (this.offer) {
        counterObj.max = this.totalCount
        counterObj.current = this.totalCount
      }

      return counterObj
    },
    quantityOptions () {
      return {
        maxDescription: 'tap to edit',
        max: 'Available',
        current: 'Result'
      }
    },
    allocationQuantityOptions () {
      return {
        maxDescription: 'tap to add all',
        max: 'Available',
        current: 'Result'
      }
    },
  },
  methods: {
    ...mapMutations([
      'addNotification',
      'addErrorNotification',
      'updateTerminalSettings'
    ]),
    handleReset () {
      this.isDoneActive = false
      this.updateTerminalSettings({ catchAll: true })
      this.offer = null
      this.place = null
      this.$refs.offerSearch.handleSearch('')
      this.action = 'offer'
    },
    onFocus () {
      this.isFocused = true

      this.updateTerminalSettings({ catchAll: false })
    },
    onFocusOut () {
      this.isFocused = false

      this.updateTerminalSettings({ catchAll: true })
    },
    isItems (items) {
      let result = []

      return items.filter(e => (e.state === 'normal' || e.state === 'new')).sort((a, b) => {
        let fa = a.state.toLowerCase(),
            fb = b.state.toLowerCase()
        if (fa[0] < fb[0]) {
          return -1
        }
        if (fa[0] > fb[0]) {
          return 1
        }
        return 0
      })

      return result
    },
    manualOfferSearch ({ search, defaultState }) {
      this.updateTerminalSettings({ catchAll: false })

      return this.$refs.offersSearchModal.show({
        search, defaultState, filter: [
          // {
          //   type: 'in',
          //   alias: 'i',
          //   field: 'state',
          //   values: ['new', 'normal']
          // },
          // {
          //   type: 'gte',
          //   field: 'items',
          //   value: 1
          // }
        ],
        'order-by': [
          { type: 'field', field: 'items', direction: 'desc' }
        ]
      })
    },
    handleSearch (searchString) {
      this.offer = null
      this.place = null
      this.places = []

      this.searchString = searchString
    },
    searchProductsSelected (offer) {
      if (!offer) {
        return null
      }

      this.offer = offer

      const query = {
        per_page: 25,
        page: 1,
        filter: [
          { type: 'eq', field: 'productOffer', value: this.offer.id },
          { type: 'in', field: 'state', values: ['new', 'normal'] }
        ],
        group: [
          { field: 'productOffer', alias: 'i' },
          { field: 'place', alias: 'i' },
          { field: 'state', alias: 'i' }
        ]
      }

      return this.$service.storageItemEntity.getAll(query).then((places) => {
        this.places = places.items
      })
    },
    searchProducts (data = {}) {
      this.action = 'offer'
      this.updateTerminalSettings({ catchAll: false })
      this.pagination = data.pagination || {}
      const query = this.$utils.buildQuery(this.pagination)

      query.search = `%` + this.sku + `%`

      return this.manualOfferSearch({
        search: query.search
      }).then(offer => {
        return this.searchProductsSelected(offer)
      })
    },
    openPlace (place) {
      this.action = 'place'
      this.updateTerminalSettings({ catchAll: false })
      this.place = place
    },
    openAllocate (quantity) {
      this.action = 'allocate'
      this.updateTerminalSettings({ catchAll: false })
      this.place = null
      this.totalCount = quantity
    },
    handleCounterSubmit (event) {
      this.updateTerminalSettings({ catchAll: false })

      this.$refs.requestDataModal.open({
        data: [
          {
            'type': 'Laminas\\Form\\Element\\Number',
            'name': 'quantity',
            'options': {
              'label': 'Write exact quantity',
              'required': true
            }
          }
        ]
      })
    },
    handleAllocationRequest (event) {
      if (event.event == 'counter.submit') {
        this.selectedQuantity = event.data.value

        this.handlePlaceRequest(event)
      }
    },
    handlePlaceRequest (event) {
      this.updateTerminalSettings({ catchAll: false })

      this.selectedQuantity = event.data?.value
      let places = this.placesToMove
      this.$refs.requestDataModal.open({
        data: [
          {
            'type': 'Laminas\\Form\\Element\\Text',
            'name': 'targetPlace',
            'options': {
              'label': 'Scan target location',
              'required': true
            }
          }
        ]
      },places,this.columns,this.pagination,this.itemStates,this.stateName)
    },
    handleRequestData (event) {
      if (event.event === 'counter.submit') {
        let cnt = event.data.value
        this.$service.storageItemEntity.patchBatch({}, {
          per_page: cnt,
          filter: [
            {
              field: 'state',
              type: 'eq',
              value: 'normal',
            },
            {
              field: 'place',
              type: 'eq',
              value: this.place.id,
            },
            {
              field: 'productOffer',
              type: 'eq',
              value: this.offer.id,
            }
          ]
        }).then(data => {
          if (this.totalCount) {
            this.totalCount = this.totalCount + parseInt(cnt)
          } else {
            this.totalCount = this.quantity.max + parseInt(cnt)
          }
          this.isDoneActive = true
        })
      } else if (event.event === 'counter.additional.submit') {
        return this.handlePlaceRequest(event)
      } else if (typeof event.quantity !== 'undefined' && !isNaN(event.quantity)) {
        let cnt = event.quantity

        this.$service.storageItemEntity.putBatch({}, {
          per_page: cnt,
          filter: [
            {
              field: 'state',
              type: 'eq',
              value: 'normal',
            },
            {
              field: 'place',
              type: 'eq',
              value: this.place.id,
            },
            {
              field: 'productOffer',
              type: 'eq',
              value: this.offer.id,
            }
          ]
        }).then(data => {
          this.totalCount = cnt
          this.isDoneActive = true
        })
      } else if (event.targetPlace) {
        const match = event.targetPlace.match(/\d+/)
        const numericPart = match ? match[0] : null

        let cnt = this.selectedQuantity
        let filter = []
        let patchData = {
          place: numericPart
        }

        if (this.place) {
          filter = [
            {
              field: 'state',
              type: 'eq',
              value: 'normal',
            },
            {
              field: 'place',
              type: 'eq',
              value: this.place.id,
            },
            {
              field: 'productOffer',
              type: 'eq',
              value: this.offer.id,
            }
          ]
        } else {
          if (this.createItem) {
            let sku
            if (this.offer.barcodes && this.offer.barcodes[0]) {
              sku = this.offer.barcodes[0]
            }

            if (this.searchString) {
              sku = this.searchString
            }

            this.$service.storageItemEntity.post({
              productOffer: {
                shop: this.offer._embedded.shop.id,
                id: this.offer.id
              },
              warehouse: 13187,
              place: numericPart,
              sku: sku,
              state: 'normal'
            }).then(() => {
              this.searchProductsSelected(this.offer)
            })

            cnt = cnt - 1
            this.createItem = false

            if (cnt === 0) {
              return
            }
          }

          filter = [
            {
              field: 'state',
              type: 'eq',
              value: 'new',
            },
            {
              field: 'productOffer',
              type: 'eq',
              value: this.offer.id,
            }
          ]

          patchData.state = 'normal'
        }

        this.$service.storageItemEntity.patchBatch([patchData], {
          per_page: cnt,
          filter: filter
        }).then(() => {
          this.addNotification(`Item was moved.`)
          if (this.totalCount) {
            this.totalCount = this.totalCount - parseInt(cnt)
          } else {
            this.totalCount = this.quantity.max - parseInt(cnt)
          }
          this.selectedQuantity = 0
          this.isDoneActive = true

          return this.loadPlace(this.place.id)
        })
      }
    },
    loadPlace (placeId) {
      return this.$service.storagePlace.get(placeId)
          .then(place => {
            this.place = place

            const query = {
              per_page: 25,
              page: 1,
              filter: [
                { type: 'eq', field: 'place', value: place.id },
                { type: 'eq', field: 'state', value: 'normal' }
              ],
              group: [
                { field: 'productOffer', alias: 'i' },
                { field: 'place', alias: 'i' },
                { field: 'state', alias: 'i' }
              ]
            }

            return this.$service.storageItemEntity.getAll(query).then(({ items }) => {
              if (!items || items.length === 0) {
                this.action = 'scanBasket'
                this.addErrorNotification(`No items found on this place.`)
                this.place = null
              } else {
                this.items = items
                this.action = 'place'
              }
            })
          })
    },
    handleFinish () {
      this.$emit('finish')
    }
  }
}
</script>
